<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content class="indexLayoutContent" style="padding: 0px">
          <div class="page flex-col">
            <div class="group_1 flex-col" style="height: 23rem">
              <div
                class="group_3 flex-row"
                v-if="barclass == 'closethis'"
                style="background-color: #000"
              >
                <div class="toplan">
                  <div class="image-wrapper_1 flex-col justify-between w-logo">
                    <a href="/">
                      <img
                        class="w-logo-img"
                        referrerpolicy="no-referrer"
                        src="../assets/imgs/logo.png"
                      />
                    </a>
                  </div>
                </div>

                <div class="backpage" @click="backpage"></div>

                <div class="darkback" :style="{ opacity: topbarOpacity }"></div>
              </div>
            </div>

            <div class="group_pcontent flex-row justify-between">
              <template v-for="(item, index) in data">
                <div class="pdssin" :key="index">
                  <div class="scontent">
                    <div
                      v-if="lag == 'en' && item.enpname != ''"
                      class="sstitle"
                      v-html="item.enpname"
                    ></div>
                    <div v-else class="sstitle" v-html="item.pname"></div>

                    <div class="ppkinds flex-row justify-start">
                      <!-- <template
                        v-for="(item2, index2) in item.keywords.split(',')"
                      >
                        <div class="ppkitem flex-col" :key="index2">
                          <span class="ppktext" v-html="item2"></span>
                        </div>
                      </template> -->

                    </div>

                    <div class="ppcontent" v-if="lag == 'en' && item.encontent != ''" v-html="item.encontent"></div>
                    <div class="ppcontent" v-else v-html="item.content"></div>
                  </div>
                </div>
              </template>
            </div>

            <footer-index :w750="w750" :basedata="basedata" :lag="lag" />
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { fetchAwardsOne, fetchBase } from "../api/index";
// const Base64 = require('js-base64').Base64
import FooterIndex from "../components/FooterIndex.vue";
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  Mousewheel,
  Scrollbar
} from "swiper";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, Mousewheel,Scrollbar]);
import "swiper/swiper-bundle.css";
import global from "../common";
import $ from "jquery";
export default {
  name: "index",
  components: {
    FooterIndex,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      pid: 0,
      lag: "",
      w750: 0,
      m_group_2_height: 0,
      basedata: [],
      frienddata: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: 68,
      thisclass2: "",

      classlist: [],

      class2list: [],

      swiper: null,

      selectIndex: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      ppindex: -1,
      pplist: [],
      bselectindex: -1,
      sectionindex: 0,
      data: [],
      dataloading: 0,
      pageSize: 12,
      query: {
        id: 0,
      },
    };
  },
  mounted() {
    let id = this.$route.params.id;
    let lag = this.$route.params.lag;
    this.pid = id;
    this.lag = lag;
    this.query.id = id;
    // alert(id);
    let that = this;
    this.handleScroll;
    that.resizepage();
    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener("resize", this.handleResize, true);

    setTimeout(() => {
      $(document).on("click", ".closesw", function () {
        $(".showppdiv").fadeTo(300, 0, () => {
          $(".showppdiv").css({ left: "-100%" });
        });
      });
    }, 1000);
    that.fetch();
    that.fetchb();
  },
  beforeCreate() {
    let that = this;
  },
  methods: {
    backpage() {
      this.$router.go(-1);
    },
    fetch(params = { id: this.pid }) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchAwardsOne(params).then((res) => {
        console.log("fetchAwardsOne:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        if (res.results.list) {
          that.data = res.results.list;
          that.nopplistshow = 0;
        } else {
          that.nopplistshow = 1;
        }
        that.dataloading = 0;
        that.pagination = pagination;
        console.log("data:", that.data);
      });
    },

    fetchb(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase(params).then((res) => {
        console.log("fetchBase:", res.results);
        that.loading = false;
        that.basedata = res.results;

        if (that.lag == "en") {
          that.basedata.tel = res.results.entel;
          that.basedata.adr = res.results.enadr;
          that.basedata.email = res.results.enemail;
        }
      });
    },

    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },
    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = window.innerHeight;
      if (window.innerWidth > 1920) {
        this.pageWidth = 1920;
      }
      if (window.innerWidth <= 750) {
        this.sectionindex = 0;
        this.m_group_2_height = pageHeight;
        this.w750 = 1;
      } else {
        this.m_group_2_height = 0;
        this.w750 = 0;
      }
      console.log("resize", this.pageWidth);
      // setTimeout(() => {
      $("html").css({ fontSize: this.pageWidth / 192 });
      // }, 500);

      // setTimeout(() => {
      //   that.resizepage();
      // },100);
    },
    handleScroll(e) {
      const winHeight =
        e.target.scrollTop || document.documentElement.scrollTop;
      // console.log(winHeight);
      if (winHeight > 400) {
        this.topbarOpacity = (winHeight - 400) / 200;
        if (this.topbarOpacity > 1) {
          this.topbarOpacity = 1;
        }
      } else {
        this.topbarOpacity = 0;
      }
    },
    goDiv(classtext, url) {
      this.barclass = "closethis";

      if (url != "") {
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
        return false;
      }
      // 获取元素在页面中的位置
      // console.log(this.$refs.dingyue_box_ref.getBoundingClientRect());

      // document
      //   .getElementById("agentTitle")
      //   .scrollIntoView({ block: "start", behavior: "smooth" });
      // 跳转到指定位置并且平滑滚动
      console.log(this.$el.querySelector(`.${classtext}`));
      this.$el
        .querySelector(`.${classtext}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    blinkshandleHover(index) {
      this.bselectindex = index;
      console.log(this.bselectindex);
    },
    sectionhandleHover(index) {
      if (this.w750 == 0) this.sectionindex = index;
    },
    blinkshandleLeave() {
      this.bselectindex = -1;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/index.css" />
<style src="../assets/css/index1280.css" />
<style src="../assets/css/index750.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
